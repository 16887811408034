<template>
  <div class="redact">
    <div class="head">
      <div
        class="item"
        :class="headTabActive == item.id ? 'active' : ''"
        @click="clickItem(item)"
        v-for="item in tabHead"
        :key="item.id"
      >
        {{ item.value }}
      </div>
    </div>
    <div class="context">
      <!-- 资讯详情 -->
      <informationParticulars
        v-show="headTabActive == 0"
        :activeId="activeId"
      ></informationParticulars>
      <!-- 标签管理 -->
      <labelManagement ref="labelManagement"  v-show="headTabActive == 1"></labelManagement>
      <!-- 半自动化标签 -->
      <robotizationlabel ref="robotizationlabel" :activeId="activeId" v-show="headTabActive == 2"></robotizationlabel>
    </div>
  </div>
</template>
<script>
import informationParticulars from '../components/informationParticulars.vue'
import labelManagement from '../components/labelManagement.vue'
import robotizationlabel from '../components/robotizationlabel.vue'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { informationParticulars, labelManagement,robotizationlabel },
  data() {
    //这里存放数据
    return {
      tabHead: [
        {
          value: '资讯详情',
          id: 0
        },

      ],
      // tab类名控制
      headTabActive: this.$route.query.headTabActive,
      // 点击编辑进来的 那一项id
      activeId: this.$route.query.id,
      // id:this.$route.query.id,
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 点击头部table栏
    clickItem(item) {
      this.headTabActive = item.id
      if(item.id==2){
        this.$refs.robotizationlabel.sing();
      }
    }
  },

  created() {
    if (this.activeId) {

      this.tabHead.push(
        {
          value: '标签管理',
          id: 1
        },
        {
          value: '半自动标签',
          id: 2
        }
      )
    }

  },

  mounted() {}
}
</script>
<style scoped lang="less">
.redact {
  background-color: #fff;
  border-radius: 8px;
  // width: 100%;
  // height: 100%;
  box-sizing: border-box;

  .head {
    // width: 100%;
    height: 70px;
    box-sizing: border-box;
    margin: 0 72px 0 22px;
    padding-left: 10px;
    display: flex;
    border-bottom: 1px solid #efefef;
    .item {
      color: #999999;
      margin-right: 50px;
      height: 100%;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 20px;
    }

    .active {
      color: #4e93fb;
      font-size: 20px;
      border-bottom: 2px solid #4e93fb;
      border-radius: 1px;
    }
  }
}
</style>
