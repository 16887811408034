<!-- 单文件上传！！！ -->
<template>
  <el-upload
    action
    :on-remove="handleRemove"
    :http-request="upload"
    :file-list="explainFileList"
    :on-preview="handlePreview"
    :show-file-list="false"
  >
    <el-button size="small" type="primary">{{ btnTxt }}</el-button>
  </el-upload>
</template>

<script>
import { uploadOneFile } from '@/api/common'
import axios from 'axios'
export default {
  props: {
    btnTxt: {
      type: String,
      default: '上传附件'
    },
    lookUrl: {
      type: String
    },
    saveUrl: {
      type: String
    }
  },

  data() {
    return {
      fileList: [],
      // fileArray: [],
      explainFileList: []
    }
  },

  computed: {
    Authorization() {
      return sessionStorage.getItem('token')
    }
  },
  watch: {
    lookUrl: {
      handler: function (newVal, oldVal) {
        if (newVal != oldVal) {
          this.updateExplainFileList()
        }
      },
      immediate: true
    }
  },

  methods: {
    updateExplainFileList() {
      if (this.lookUrl) {
        let name = this.lookUrl.substring(this.lookUrl.lastIndexOf('/') + 1)
        name = name.split('?')[0]
        this.explainFileList.push({
          name,
          url: this.lookUrl
        })
        // console.log(this.lookUrl)
      } else {
        this.explainFileList = []
      }
    },
    handleRemove() {
      this.explainImgList = []
      this.$emit('update:saveUrl', '')
    },
    // 上传
    async upload(file) {
      this.explainFileList = []
      const form = new FormData()
      form.append('file', file.file)
      form.append('Authorization', this.Authorization)
      const { data: res } = await uploadOneFile(form)
      if (res.resultCode === 200) {
        const url = res.data
        this.$emit('update:lookUrl', url)
        this.$emit('update:saveUrl', url)
        this.$emit('change', url)
      }
    },
    clear() {
      this.$emit('update:lookUrl', '')
      this.$emit('update:saveUrl', '')
      this.$emit('change', '')
      this.explainFileList = []
    },
    handlePreview() {
      const url = this.saveUrl
      // console.log(url) //短路径
      axios({
        method: 'GET',
        url: process.env.VUE_APP_BASE_API + 'upload/uploadFile',
        params: { urlStr: url },
        responseType: 'blob',
        headers: {
          // 设置请求头
          token: this.Authorization
        }
      })
        .then((res) => {
          // console.log(res);
          let blob = new Blob([res.data], {
            type: `application/msword`
          })
          let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
          const a = document.createElement('a') //创建a标签
          a.style.display = 'none'
          a.href = href // 指定下载'链接
          a.download = this.saveUrl.substring(this.saveUrl.lastIndexOf('/') + 1) //指定下载文件名
          a.click() //触发下载
          URL.revokeObjectURL(a.href) //释放URL对象
          document.body.removeChild(a) //释放标签
        })
        .catch(() => {
          //this.$message.error('文件下载失败')
        })
    }
  }
}
</script>

<style></style>
